<template>
    <div class="terms">
        <h1 v-html="i18n.title"></h1>

        <h2 v-html="i18n.subtitle"></h2>

        <ul class="terms__info">
            <li
                v-for="item in i18n.info"
                :key="item.bold"
            >
                <b v-html="item.bold"></b> {{ item.text }}
            </li>
        </ul>

        <p v-html="i18n.text1"></p>

        <p v-html="i18n.text2"></p>

        <div
            v-for="item in i18n.content"
            :key="item.heading"
        >
            <h3 v-html="item.heading"></h3>

            <dl>
                <div
                    v-for="point in item.points"
                    :key="point.point"
                >
                    <dt v-html="point.point"></dt>

                    <dd v-html="point.text"></dd>
                </div>
            </dl>
        </div>

        <p class="terms__end-text" v-html="i18n.endText"></p>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'TermsComponent',
    computed: {
        ...mapState(['locales']),
        i18n() {
            return this.$i18n.messages[this.locales.selected].terms
        },
    },
}
</script>

<style lang="scss">
.terms {
    text-align: justify;

    h1 {
        font-size: 16px;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        margin-bottom: 6px;
    }

    dl {
        margin-top: 0;
    }

    dt {
        float: left;
    }

    dd {
        margin-bottom: 12px;
    }

    &__info {
        font-size: 14px;

        @media (min-width: 1300px) {
            font-size: 16px;
        }
    }

    &__third-parties {
        li {
            list-style-type: circle;
            margin-left: 36px;
        }
    }

    &__end-text {
        font-size: 14px;
    }
}
</style>
