v<template>
    <div class="terms-view">
        <terms-component />
    </div>
</template>

<script>
import TermsComponent from '@/components/TermsComponent'

export default {
    name: 'TermsView',
    metaInfo() {
        return {
            title: "Webby Apps - Terms and conditions",
            meta: [
                {
                    name: 'description',
                    content: `
                        At Webby Apps we make all kind of websites. From portfolio to ecommerce. All websites are
                        customized for your needs. Almost everything's possible. Check out and read our Terms and conditions here.
                    `
                },
                {
                    property: 'og:title',
                    content: "Webby Apps - Terms and conditions"
                },
                {
                    property: 'og:site_name',
                    content: 'Webby Apps'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    name: 'robots',
                    content: 'index,follow'
                }
            ]
        }
    },
    components: {
        TermsComponent,
    },
    mounted() {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 0)
    },
}
</script>

<style lang="scss">
.terms-view {
    padding-top: 66px;

    @media (min-width: 768px) {
        padding: 90px 24px 48px;
    }

    @media (min-width: 1024px) {
        padding: 108px 48px 48px;
    }

    @media (min-width: 1300px) {
        padding: 132px 66px 66px;
    }
}
</style>
